import { render, staticRenderFns } from "./Blog.vue?vue&type=template&id=215204f4&"
var script = {}


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vue-cli-plugin-import-components */
import Navbar from "@/components/Navbar.vue";
script.components = Object.assign({}, { Navbar }, script.components);